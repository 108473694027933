<template>
  <SettingsLayout>
    <div class="card h-100">
      <div class="card-body p-3">
        <h6 class="main-title">Security</h6>
        <div class="row mt-2 gy-4 content-box">
          <div class="col-12">
            <div class="d-flex justify-content-between flex-wrap gap-2">
              <h6 class="title fw-500 m-0">Email Verification</h6>
              <span class="sub-title font-secondary">Protect your account and transactions.</span>
              <!-- <div class="col-lg-8 col-12">
              </div> -->
              <div class="form-check form-switch text-lg-end p-0">
                <input type="checkbox" :checked="selected == 2" class="btn-check filter-check"
                  @click="change_setting($event.target.value)" :value="selected == 2 ? 0 : 2" id="btn-check-1">
                <label class="btn btn_checks" for="btn-check-1">{{ selected == 2 ? 'Disable' : 'Enable' }}</label>
                <!-- <input class="form-check-input" type="checkbox" @click="change_setting($event.target.value)"
                  :value="selected == 2 ? 0:2" role="switch" id="flexSwitchCheckDefault"> -->
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-between flex-wrap gap-2">
              <h6 class="title fw-500 m-0">SMS Verification</h6>
              <span class="sub-title font-secondary">Protect your account and transactions.</span>
              <!-- <div class="col-lg-8 col-12">
              </div> -->
              <div class="form-check form-switch text-lg-end p-0">
                <input type="checkbox" :checked="selected == 1" class="btn-check filter-check"
                  @click="change_setting($event.target.value)" :value="selected == 1 ? 0 : 1" id="btn-check-2">
                <label class="btn btn_checks" for="btn-check-2">{{ selected == 1 ? 'Disable' : 'Enable' }}</label>
              </div>
            </div>
            <!-- <div class="d-flex gap-2">
              <div class="email_icon">
                <i class="far fa-paper-plane"></i>
              </div>
            </div> -->
          </div>
          <!-- <div class="col-12">
            <div class="d-flex gap-4 justify-content-between align-items-center">
              <div class="d-flex gap-3">
                <div class="google_icon"><i class="fa-brands fa-google"></i></div>
                <div>
                  <h6 class="title fw-500 m-0">Google Authentication
                  </h6>
                  <span class="sub-title font-secondary">Turn on Google Authentication to make your account more
                    secure.</span>
                </div>
              </div>
                <label v-if="loading" type="button" class="btn btn_checks">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </label>
                <label v-else type="button" class="btn btn_checks" @click.prevent="get2fa_">Manage
                </label>
            </div>
          </div> -->
          <hr />
          <div class="col-12">
            <div class="row gy-2">
              <div class="col-lg-8 col-12">
                <h6 class="title"> Anti-Phishing Code <span
                    class="badge bg-primary small rounded-pill ms-2">coming</span>
                </h6>
                <span class="sub-title">The anti-phishing code can protect you from phishing attempts and
                  frauds.</span>
              </div>
              <div class="form-check form-switch col-lg-4 col-12 text-lg-end">
                <button class="btn btn_setup">Setup</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content  -->
  </SettingsLayout>
</template>



<script>
import ApiClassLocal from "@/api/localapi";
import { Modal } from 'bootstrap'
import SettingsLayout from "@/layouts/SettingsLayout.vue";
// import GoogleAuthenticator from "@/components/Account/Security/GoogleAuthenticator.vue";
// import RemoveTwoStepModal from "@/components/modal/RemoveTwoStepModal.vue";
import ApiClass from "@/api/api";
export default {
  name: "TwoFactor",
  data() {
    return {
      selected: null,
      btn_show: false,
      loading: false,
      is_2fa: false,
      setup: "",
      theModal: null,
      res: {
        res: [],
        is_visible: false,
      },
    };
  },
  components: {
    SettingsLayout,
    // GoogleAuthenticator,
    // RemoveTwoStepModal,
  },
  mounted() {
    this.get_setting();
  },
  methods: {
    async get2fa_() {
      this.res.is_visible = false;
      this.loading = true;
      var result = await ApiClassLocal.getNodeRequest('user/setup2FA', true, null, { userId: 11 });
      if (result.data.status_code == 1) {
        this.res.is_visible = true;
        this.res.res = result.data.data;
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.loading = false;

    },
    handleRemove() {
      let el = this.$refs.removeTwoStep.$el
      this.theModal = new Modal(el, {})
      this.theModal.show()
    },
    removeTwoFactor() {
      // call Api
      this.theModal.hide()
      this.change_setting(5);
    },
    closeModal() {
      this.theModal.hide()
    },
    async get_setting() {
      await ApiClass.getRequest("2fa/get", true).then((res) => {
        if (res.data.status_code == 1) {
          this.selected = res.data.data._2fa;
        }
        console.log(res);
      });
    },
    async change_setting(value) {
      this.btn_show = false;
      this.loading = true;
      await ApiClass.postRequest("2fa/update", true, {
        two_factor: value,
      }).then((res) => {
        if (res.data.status_code == 1) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          this.get_setting();
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.form-check-input:checked[type=checkbox] {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  /* background-image: none; */
}


.email_icon {
  padding: 0.4rem 0.5rem;
  /* background-color: #a50000de; */
  border-radius: 5px;
  line-height: 10px;
  height: 100%;
}

.sms_icon {
  padding: 0.4rem 0.5rem;
  background-color: #0fa961;
  border-radius: 5px;
  line-height: 10px;
  height: 100%;
}

.google_icon {
  padding: 0.4rem 0.5rem;
  background-color: var(--bit-referral);
  border-radius: 5px;
  line-height: 10px;
  height: 100%;
}

.email_icon i,
.sms_icon i,
.google_icon i {
  color: var(--primary-2);
  font-weight: 800;
  font-size: 18px;
}

label.btn.btn_checks {
  font-size: 12px;
  color: var(--text);
  font-weight: 500;
  background: var(--bg5);
  border: none;
}

.filter-check:checked+.btn {
  background-color: rgba(77, 202, 136, 0.15);
  border: none !important;
  color: #4DCA88;
}

.btn.btn_setup {
  font-size: 12px;
  color: var(--text);
  font-weight: 600;
  background: var(--bg5);
  border: none;
}
</style>
