<template>
    <section class="card-tm" id="settings">
        <div class="container-fluid px-3">
            <div class="row py-2 justify-content-center">
                <div class="text-end  col-12">
                    <a data-bs-toggle="offcanvas" href="#sidebar_offcanvas" role="button"
                        class="d-lg-none d-block pb-2 text-secondary">
                        <i class="fa fa-bars fa-xl"></i>
                    </a>
                </div>
                <div class="offcanvas card offcanvas-end p-3" tabindex="-1" id="sidebar_offcanvas">
                    <div class="offcanvas-body sidebar-col" id="settings-sidebar">
                        <div class="text-end pb-4">
                            <a href="javascript:void(0);" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><i
                                    class="fa-solid fa-xmark"></i></a>
                        </div>
                        <div id="settings-sidebar">
                            <ul class="sidebar-ul d-flex flex-column text-nowrap overflow-auto gap-2" id="pills-tab"
                                role="tablist">
                                <li class="" v-for="(item, index) of topSidebar.sidebarItems" v-bind:key="index"
                                    v-bind:class="item.link == topSidebar.activetab ? 'active' : ''">
                                    <router-link class="d-flex gap-3 align-items-center" :to="item.is_live ? item.link : ''"
                                        v-bind:class="item.link == topSidebar.activetab ? 'text-primary' : ''">
                                        <span>{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- <ul class="sidebar-ul" id="pills-tab" role="tablist">
                        <li data-bs-parent="#settings-sidebar" class="" v-for="(item, index) of sidebarItems"
                            v-bind:key="index" v-bind:class="item.link == activetab ? 'active' : ''">
                            <router-link class="d-flex gap-3 align-items-center" :to="item.link">
                                <img :src="require(`@/assets/images/${$theme == 1 ? `icons/dark/${item.icon}` : `icons/${item.icon}`}`)"
                                    class="img-fluid" width="25" />
                                <span>{{ item.name }}</span>
                                <span class="badge bg-primary small" v-if="!item.status">coming</span>
                            </router-link>
                        </li>
                    </ul> -->
                    </div>
                </div>
                <div class="col-lg-2 col-md-12 d-lg-block d-none">
                    <div class="card col h-100 sidebar-col p-2 shadow-sm" id="settings-sidebar">
                        <ul class="sidebar-ul d-flex flex-column text-nowrap overflow-auto gap-2" id="pills-tab"
                            role="tablist">
                            <li class="" v-for="(item, index) of topSidebar.sidebarItems" v-bind:key="index"
                                v-bind:class="item.link == topSidebar.activetab ? 'active' : ''">
                                <router-link class="d-flex gap-3 align-items-center" :to="item.is_live ? item.link : ''"
                                    v-bind:class="item.link == topSidebar.activetab ? 'text-primary' : ''">
                                    <span>{{ item.name }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 col-lg ps-lg-0">
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//import Setting from "@/components/Setting.vue";
// import TopSidebar from "@/components/ui/TopSidebar.vue";
export default {
    name: "SettingsLayout",
    components: {
        // TopSidebar
    },
    data() {
        return {
            topSidebar: {
                activetab: null,
                themeColor: 0,
                sidebarItems: [
                    { 'name': 'Account', 'icon': 'profile.svg', 'link': '/settings/account', 'is_live': true },
                    { 'name': 'KYC verification', 'icon': 'kyc.svg', 'link': '/settings/kyc', 'is_live': true },
                    { 'name': 'Security', 'icon': 'verify.svg', 'link': '/settings/security', 'is_live': true },
                    { 'name': 'Change Password', 'icon': 'changepassword.svg', 'link': '/settings/change-password', 'is_live': true },
                    { 'name': 'Referral Program', 'icon': 'refferal.svg', 'link': '/account/referral-friends', 'is_live': true },
                    // { 'name': 'My Fees', 'icon': 'fee.svg', 'link': '/settings/fees', 'is_live': false },
                    // { 'name': 'Giveaways', 'icon': 'giveaway.svg', 'link': '#', 'is_live': false },
                    // { 'name': 'Reward Center', 'icon': 'refferal.svg', 'link': '#', 'is_live': false },
                ],
            }
        }
    },
    mounted() {
        this.topSidebar.themeColor = this.$store.getters.getTheme;
        this.topSidebar.activetab = this.$route.path;
    },
    watch: {
        "$store.getters.getTheme": function () {
            this.topSidebar.themeColor = this.$store.getters.getTheme;
        },
        $route: function () {
            this.topSidebar.activetab = this.$route.fullPath;
        }
    },
};
</script>

<style scoped>
.form-check-input:checked {
    background-color: var(--dark-yellow) !important;
    border-color: var(--dark-yellow) !important;
}

.circle {
    clip-path: circle() !important;
    padding: 0.4rem;
    font-size: 0.9rem;
    color: white;
    background-color: var(--text-black) !important;
}

ul {
    list-style-type: none;
}


.profile-col {
    border-bottom-right-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

#sidebar_offcanvas{
    z-index: 9999;
}



#pills-tabContent .card:hover {
    box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .075) !important;
}


#email-col {
    font-size: 1.3rem !important;
    font-weight: 600;
    color: var(--text-black);
}

#signup {
    color: var(--text-black);
    font-size: 0.7rem;
    font-weight: 500;
}

#id {
    font-size: 0.8rem;
    font-weight: 500;
}

.card {
    border-width: 0.05rem !important;
    border-color: rgb(232, 232, 232) !important;
    box-shadow: 0 .1rem 0.3rem rgba(0, 0, 0, .055) !important;
}

#kyc-row {
    margin-top: 1.7rem;
}

#kyc-row .title {
    font-size: 1.15rem;
    font-weight: 600
}

#kyc-row .side-option a {
    font-size: 0.75rem;
    color: var(--text-black);
}

#kyc-row .side-option a:hover {
    color: var(--dark-yellow);
}


#kyc-row .dis {
    font-size: 0.75rem;
    color: var(--text-black);
}

#kyc-row .fotter {
    font-size: 0.9rem;
    color: var(--dark-yellow);
}


#myfee .footer {
    font-size: 0.7rem;
}

#Security-Settings .footer {
    font-size: 0.7rem;
}

#Security-Settings .footer .binding a {
    color: var(--dark-yellow);
}

/* .navbar {
    display: none !important;
}

#settings .sidebar-ul li {
    padding: 0.7rem;
    border-radius: 5px;
} */

#settings .sidebar-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    background-color: white !important;
}

/* KYC tab css */

#pills-KYC .card {
    border-bottom-right-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;

}

#pills-KYC input {
    font-size: 0.8rem;
    color: rgb(150, 150, 150);
}

#pills-KYC select {
    font-size: 0.8rem;
    color: rgb(150, 150, 150);
}

.submit-btn {
    background-color: var(--dark-yellow) !important;
    color: white !important;
    font-weight: 500 !important;
}

#pills-KYC label {
    font-size: 0.93rem;
    font-weight: 600;
    color: rgb(121, 121, 121);
}

#pills-KYC ::placeholder {
    font-size: 0.82rem;
    font-weight: 400;
    color: rgb(150, 150, 150);
}

#pills-KYC p {
    font-size: 1.2rem;
    font-weight: 700;
}

#pills-KYC .card-title {
    color: var(--text-black);
}


#pills-2FA .card-title {
    font-weight: 600;
}

#pills-2FA .card-text {
    font-size: 0.85rem;
    color: #a1a1a1;

}

#pills-2FA #toggle {
    font-size: 1rem;
    color: #a1a1a1;
    font-weight: 500;

}

#pills-2FA .card-footer {
    background-color: white !important;
    border: 0rem !important;
}

#pills-2FA .main-card .outer-row {
    margin-top: 1rem;
}

#pills-2FA .btn {
    background-color: var(--dark-yellow);
    color: white;
    font-weight: 600;
    font-size: 0.8rem !important;
}

#pills-2FA a {
    color: var(--dark-yellow);
    font-weight: 600;
    font-size: 0.8rem !important;
    text-decoration: none;
}

#pills-2FA .card-title {
    font-weight: 600;
}

#pills-2FA .card-text {
    font-size: 0.85rem;
    color: #a1a1a1;

}

#pills-2FA #toggle {
    font-size: 1rem;
    color: #a1a1a1;
    font-weight: 500;

}

#pills-2FA .card-footer {
    background-color: white !important;
    border: 0rem !important;
}

#pills-2FA .main-card .outer-row {
    margin-top: 1rem;
}

#pills-2FA .btn {
    background-color: var(--dark-yellow);
    color: white;
    font-weight: 600;
    font-size: 0.8rem !important;
}

#pills-2FA a {
    color: var(--dark-yellow);
    font-weight: 600;
    font-size: 0.8rem !important;
    text-decoration: none;
}




#pills-Settings .card-title {
    font-weight: 600;
}

#pills-Settings .card-text {
    font-size: 0.85rem;
    color: #a1a1a1;

}

#pills-Settings #toggle {
    font-size: 1rem;
    color: #a1a1a1;
    font-weight: 500;

}

#pills-Settings .card-footer {
    background-color: white !important;
    border: 0rem !important;
}

#pills-Settings .main-card .outer-row {
    margin-top: 1rem;
}

#pills-Settings .btn {
    background-color: var(--dark-yellow);
    color: white;
    font-weight: 600;
    font-size: 0.8rem !important;
}

#pills-Settings a {
    color: var(--dark-yellow);
    font-weight: 600;
    font-size: 0.8rem !important;
    text-decoration: none;
}

#pills-Settings .card-title {
    font-weight: 600;
}

#pills-Settings .card-text {
    font-size: 0.85rem;
    color: #a1a1a1;

}

#pills-Settings #toggle {
    font-size: 1rem;
    color: #a1a1a1;
    font-weight: 500;

}

#pills-Settings .card-footer {
    background-color: white !important;
    border: 0rem !important;
}

#pills-Settings .main-card .outer-row {
    margin-top: 1rem;
}

#pills-Settings .btn {
    background-color: var(--dark-yellow);
    color: white;
    font-weight: 600;
    font-size: 0.8rem !important;
}

#pills-Settings a {
    color: var(--dark-yellow);
    font-weight: 600;
    font-size: 0.8rem !important;
    text-decoration: none;
}

#pills-Password p {
    color: #8e8e8e;
    font-weight: 600;
    font-size: 1rem !important;
}


#pills-Password .card-title p {
    color: var(--text-black);
    font-weight: 600;
    font-size: 1.25rem !important;

}

#pills-Password ::placeholder {
    font-size: 0.82rem;
    font-weight: 400;
    color: rgb(150, 150, 150);
}

#pills-Password .btn {
    font-size: 0.82rem;
    font-weight: 400;
    color: white;
}


@media screen and (min-device-width:200px) and (max-device-width:1200px) {
    #myfee {
        margin-top: 1.5rem !important;
    }

    .profile-data-row {
        margin-bottom: 3rem !important;
    }

    #email-col {
        font-size: 0.8rem !important;
    }

    .submit-btn {
        width: 100% !important;
    }

}
</style>