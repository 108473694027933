<template>
  <div class="card-tm">
    <div class="card">
      <div class="card-body gradiant-bg3 py-5">
        <div class="row gy-5 py-lg-4 justify-content-center text-center">
          <div>
            <h5 class="text-white fw-500 fs-2">How Can We Help You?</h5>
            <p class="font-light-gray2 mt-3 fs-18">If you don't find answer you can submit your ticket hassel free.</p>
          </div>
          <div class="col-lg-3 col-12 mx-3">
            <div class="card shadow">
              <div class="card-body p-3">
                <div class="d-flex gap-4 flex-column">
                  <span class="text-primary"><i class="far fa-comments fa-3x"></i></span>
                  <div>
                    <h6 class="fw-500 fs-4">Support Ticket</h6>
                    <span class="font-light-gray">We attempt to respond to support requests within 1 business day.</span>
                  </div>
                  <button data-toggle="modal" data-target="#support-ticket" type="button" class="btn btn-primary">Create Ticket</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mx-3">
            <div class="card shadow">
              <div class="card-body p-3">
                <div class="d-flex gap-4 flex-column">
                  <span class="text-primary"><i class="fas fa-receipt fa-3x"> </i></span>
                  <div>
                    <h6 class="fw-500 fs-4">Tickets List</h6>
                    <span class="font-light-gray">Track you ticket status of all past tickets at one place.</span>
                  </div>
                  <router-link :to="{name:'TicketList'}" class="btn btn-primary text-white">
                    View Tickets
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SupportTicket/>
  </div>
</template>

<script>
import SupportTicket from "@/components/modal/SupportTicket";

export default {
  name: "Support",
  components: {
    SupportTicket
  },
};
</script>