<template>
  <div>
    <div class="modal" id="support-ticket" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog card-tm modal-dialog-centered">
        <div class="modal-content card">
          <div class="modal-header border-0">
            <h5 class="modal-title">Ticket</h5>
            <button type="button" class="btn close-btn" @click="close" data-dismiss="modal" aria-label="Close" ref="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="col-md-12">
              <form class="row gy-3" @submit.prevent="submit">
                <div class="form-group">
                  <label class="form-label">Subject</label>
                  <input type="text" class="form-control" placeholder="Enter subject"
                    v-model.trim="$v.form.title.$model" />
                  <div class="error" v-if="submitted && !$v.form.title.required">
                    Subject is required
                  </div>
                </div>
                <div class="form-group">
                  <label for="validationCustom04" class="form-label">Ticket Type</label>
                  <select class="form-select" id="validationCustom04" v-model.trim="form.category_id">
                      <option :value="item.id" v-for="(item,k) in ticketlist" :key="k">
                        {{ item.name }}
                      </option>
                    </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Name</label>
                    <input type="text" class="form-control" placeholder="Enter name"
                      v-model.trim="$v.form.author_name.$model" />
                    <div class="error" v-if="submitted && !$v.form.author_name.required">
                      Name is required
                    </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <input type="text" class="form-control" placeholder="Enter Email" value=""
                    v-model.trim="$v.form.author_email.$model" />
                  <div class="error" v-if="submitted && !$v.form.author_email.required">
                    Email is required
                  </div>
                  <div class="error" v-if="submitted && !$v.form.author_email.email">
                    This must be an email
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Ticket Description</label>
                  <textarea class="form-control" placeholder="How can we help you today?" id="floatingTextarea" rows="2"
                    v-model.trim="$v.form.content.$model"></textarea>
                  <div class="error" v-if="submitted && !$v.form.content.required">
                    Ticket Description is required
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary w-100">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Modal } from "bootstrap";
import ApiClass from "@/api/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "SupportTicket",
  data() {
    return {
      ticketlist: [],
      // theModal: null,
      loading: false,
      form: {
        title: "",
        category_id: 1,
        author_name: null,
        author_email: null,
        content: null,
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      title: { required },
      author_name: { required },
      author_email: { required, email },
      content: { required },
    },
  },
  async mounted() {
    var url = "ticket_type/get";
    await ApiClass.getRequest(url, true).then((response) => {
      this.ticketlist = response.data.data;
      this.form.category_id = response.data.data[0]?.id;
    });
    // this.theModal = new Modal(document.getElementById("support-ticket"));
  },
  methods: {
    close(){
      this.form.title = this.form.author_email = this.form.author_name = this.form.content = null; 
      this.$refs.Close.click();
    },
    async submit() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      this.loading = true
      await ApiClass.postRequest("ticket/create", true, this.form).then(
        (response) => {
          if (response.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.form = [];
            this.submitted = this.loading = false;
            return this.close();
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.submitted = this.loading = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 2rem;
}

/* .modal {
  background-color: #edf0f2bf;
} */

.modal{
  z-index: 9999;
}
.modal-header .close-btn {
  border-radius: 50%;
  padding: .2rem .6rem;
}

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* color: rgb(72, 81, 86); */
  margin-bottom: 0;
}

.is_dark .modal-header button {
  color: white;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}

.modal-header {
  background: var(--bg5);
}
</style>
