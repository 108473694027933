<template>
  <SettingsLayout>
    <div class="row">
      <div class="col">
        <div class="col-md-12">

          <!-- show rejected messages -->
          <div v-show="user.user_kyc_status == 'rejected'" class="text-center alert alert-danger">
            <span>Your Kyc is <b>Rejected</b> due to following Reasons. <br />
              <p style="font-size: small; color: red">
                {{ user.user_kyc_status_message }}
              </p>
            </span>
          </div>
        </div>
        <div class="card" v-if="user.user_kyc_status == 'new' || user.user_kyc_status == 'rejected'">
          <div class="card-body">
            <h6 class="main-title">Indentification</h6>
            <p class="small mb-3"><strong>Important</strong>: one account can only choose one identity type and no
              change is allowed after verifying.</p>

            <form @submit.prevent="handleSubmit">
              <div class="card">

                <div class="card-body">
                  <p class="fw-semibold mb-3">
                    <span class="fa fa-circle-info primary2"></span> Personal Information
                  </p>
                  <div class="row">
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">First Name</label>
                        <input placeholder="Enter First Name" autocomplete="off" type="text" v-model="formdata.first_name"
                          class="form-control " :class="{
                            'is-invalid': isSubmitted && $v.formdata.first_name.$error,
                          }" id="first_name" v-on:keypress="isLetter($event)" aria-describedby="emailHelp" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Middle Name</label>
                        <input placeholder="Enter Middle Name" autocomplete="off" type="text"
                          v-model="formdata.middle_name" class="form-control " :class="{
                            'is-invalid': isSubmitted && $v.formdata.middle_name.$error,
                          }" id="middle_name" v-on:keypress="isLetter($event)" aria-describedby="emailHelp" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Last Name</label>
                        <input placeholder="Enter Last Name" autocomplete="off" type="text" v-model="formdata.last_name"
                          class="form-control " :class="{
                            'is-invalid': isSubmitted && $v.formdata.last_name.$error,
                          }" id="last_name" v-on:keypress="isLetter($event)" aria-describedby="emailHelp" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Date Of Birth</label>

                        <date-picker placeholder="DOB" v-model="formdata.dob" valueType="format" class="w-100"
                          :class="{ 'is-invalid': isSubmitted && $v.formdata.dob.$error }" id="dob">
                        </date-picker>

                        <div v-if="isSubmitted && !$v.formdata.dob.required" class="invalid-feedback">
                          Date of birth is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Address</label>
                        <textarea placeholder="Enter Address" v-model="formdata.address" class="form-control " :class="{
                          'is-invalid': isSubmitted && $v.formdata.address.$error,
                        }" rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-3">
                <div class="card-body">
                  <p class=" mb-3 fw-semibold">
                    <span class="fa fa-circle-user primary2"></span>
                    Identity verification
                  </p>
                  <div class="row">
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Indentity Type</label>
                        <select v-model="formdata.document_type" placeholder="Choose One..." id="document_type"
                          class="form-control form-select" :class="{
                            'is-invalid': isSubmitted && $v.formdata.document_type.$error,
                          }" aria-label="Default select example">
                          <option>Choose one...</option>
                          <option value="NationID">
                            Nation ID
                          </option>
                          <option value="DrivingLicense">
                            Driving License
                          </option>
                          <option value="Passport">
                            Passport
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Indentity
                          Number</label>
                        <input placeholder="Enter Number" autocomplete="off" v-model="formdata.document_number"
                          type="text" class="form-control " :class="{
                            'is-invalid': isSubmitted && $v.formdata.document_type.$error,
                          }" id="document_number" v-on:keypress="count($event, formdata.document_number.length)"
                          aria-describedby="emailHelp" />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label class="form-label">Upload Front</label>
                        <!-- <div class="text-center" @click="check('document_type_pan1', 'img_url1')">
                          <label for="img_url1">
                            <i class="fas fa-upload fs-3 text-dark bg-light p-2 rounded-circle" id="img_url1"></i>
                          </label>
                        </div> -->
                        <input type="file" class="form-control" id="document_type_pan1" accept="image/*"
                          v-on:change="first($event, 'first_image')" />
                        <div v-if="isSubmitted && this.first_image == null" class="error">
                          Image is required
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="mb-3">
                        <label class="form-label">Upload Back</label>
                        <!-- <div class="text-center" @click="check('document_type_pan2', 'img_url2')">
                          <label for="img_url2">
                            <i class="fas fa-upload fs-3 text-dark bg-light p-2 rounded-circle" id="img_url2"></i>
                          </label>
                        </div> -->
                        <input type="file" id="document_type_pan2" class="form-control" accept="image/*"
                          v-on:change="first($event, 'second_image')" />
                        <div v-if="isSubmitted && this.second_image == null" class="error">
                          Image is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn w-100 mt-3 btn-primary py-2">
                    Confirm & Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="card p-4 px-0 text-center" v-else>
          <div class="card-body ">
            <div class="col-12">
              <span v-show="user.user_kyc_status == 'completed'" class="fs-20 alert alert-success py-4">
                <i class="fa fa-circle-check fs-3 "></i> Your Kyc is Verified and
                Completed.
              </span>
              <span v-show="user.user_kyc_status == 'pending'" class="fs-20 alert alert-warning py-4 mx-auto"> <i
                  class="fa fa-circle-info "></i> Your Kyc has been
                submitted successfully and Pending For
                Approval.</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Content  -->
  </SettingsLayout>
</template>


<script>
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";
import ApiClass from "@/api/api";
import { required } from "vuelidate/lib/validators";
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import img from "@/assets/img-preview.png";
import img1 from "@/assets/img-preview.png";
import img2 from "@/assets/img-preview.png";
export default {
  name: "KycSetting",
  components: {
    SettingsLayout,
    // DatePick,
    DatePicker,
  },
  data() {
    return {
      tick: '<svg xmlns="http://www.w3.org/2000/svg" class="align-middle" width="40" height="40" viewBox="0 0 24 24" style="fill: var(--green);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path></svg>',
      first_image: null,
      second_image: null,
      formdata: {
        first_name: null,
        middle_name: null,
        last_name: null,
        dob: null,
        address: null,
        document_type: null,
        document_number: null,
      },
      pan_card: img,
      pan_card1: img1,
      pan_card2: img2,
      isSubmitted: false,
      date: new Date(),
      user: {},
    };
  },
  validations: {
    formdata: {
      first_name: {
        required,
      },
      middle_name: {},
      last_name: {
        required,
      },
      address: {
        required,
      },
      dob: {
        required,
      },
      document_type: {
        required,
      },
      document_number: {
        required,
      },

    },
  },
  mounted() {
    this.userDatas();
  },
  methods: {
    userDatas() {
      var userdata = localStorage.getItem("user");
      this.user = JSON.parse(userdata);
    },
    check(id, img) {
      document.getElementById(id).click();
      var x = document.getElementById(id);
      x.onchange = () => {
        var a = document.getElementById(id);
        const [file] = a.files;
        if (file) {
          console.log(id);
          var b = document.getElementById(img);
          b.src = URL.createObjectURL(file);
        }
      };
    },

    async handleSubmit() {
      this.isSubmitted = true;

      this.$v.$touch();
      if (this.getAge(this.formdata.dob) < 18) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Age Should be greater then 18",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      if (
        !this.$v.$invalid &&
        this.first_image != null &&
        this.second_image != null
      ) {
        var all_data = new FormData();
        all_data.append("identity_front_path", this.first_image);
        all_data.append("identity_back_path", this.second_image);
        all_data.append("first_name", this.formdata.first_name);
        all_data.append("middle_name", this.formdata.middle_name);
        all_data.append("last_name", this.formdata.last_name);
        all_data.append("date_birth", this.formdata.dob);
        all_data.append("address", this.formdata.address);
        all_data.append("identity_type", this.formdata.document_type);
        all_data.append("identity_number", this.formdata.document_number);
        var headers = {
          "Content-Type": "multipart/form-data",
        };
        console.log(all_data);
        await ApiClass.postRequest(
          "userkyc/create",
          true,
          all_data,
          headers
        ).then((res) => {
          // console.log(res);
          // console.log(res.data.status_code);
          if (res.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.user.user_kyc_status = "pending";
            localStorage.setItem("user", JSON.stringify(this.user));
            this.userDatas();
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
      }
    },
    first(event, var_name) {
      if (var_name == "first_image") {
        this.first_image = event.target.files[0];
        console.log(this.first_image);
      } else if (var_name == "second_image") {
        this.second_image = event.target.files[0];
        console.log(this.second_image);
      } else if (var_name == "third_image") {
        this.third_image = event.target.files[0];
        console.log(this.third_image);
      }
    },
    checkColor(status) {
      if (status == "pending") {
        return {
          backgroundColor: "#fffd29",
          color: "#000",
        };
      }
      return {
        backgroundColor: "#008000",
        color: "#fff",
      };
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    count(e, number) {
      if (number > 19) {
        e.preventDefault();
      }
    },
  },
};
</script>


<style scoped>
.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pb40 {
  padding-bottom: 40px;
}

.pt60 {
  padding-top: 60px;
}

.pb60 {
  padding-bottom: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pb70 {
  padding-bottom: 70px;
}

.pt130 {
  padding-top: 130px;
}

.pb130 {
  padding-bottom: 130px;
}

.pt150 {
  padding-top: 150px;
}

.pb150 {
  padding-bottom: 150px;
}

.pt180 {
  padding-top: 180px;
}

.pb180 {
  padding-bottom: 180px;
}

.pt200 {
  padding-top: 200px;
}

.pb200 {
  padding-bottom: 200px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.ugf-bg {
  background: #f5f7fa;
}

.ugf-nav {
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

.navigation .logo a span {
  background: #e5e5e5;
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.navigation .nav-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation .nav-btns .back {
  font-size: 1.4rem;
  font-weight: 400;
  color: #546274;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: "Roboto", sans-serif;
  flex-flow: "Roboto", sans-serif;
  margin-right: 25px;
}

.navigation .nav-btns .back:hover {
  color: #1e85ff;
}

.navigation .nav-btns .get {
  background: #1e85ff;
  color: #ffffff;
  height: 50px;
  padding: 0 30px;
  border-radius: 5px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 50px;
}

.ugf-progress {
  position: relative;
}

.ugf-progress .progress {
  position: fixed;
  z-index: 3;
  top: 43px;
  height: 4px;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 350px;
  border-radius: 4px;
  overflow: hidden;
}

.ufg-main-container {
  min-height: 100vh;
  padding: 170px 0;
}

.ugf-form .input-block+.input-block {
  margin-top: 40px;
}

.ugf-form .input-block h4 {
  font-size: 2.0rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #1c2437;
}

.ugf-form .input-block h4+div {
  margin-top: 15px;
}

.ugf-form .input-block p {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #546274;
  margin-top: 5px;
}

.ugf-form .input-block p+div {
  margin-top: 15px;
}

.ugf-form .input-block .country-select-wrap .country-select {
  width: 100%;
}

.ugf-form .input-block .country-select-wrap .country-select .flag-dropdown .selected-flag {
  padding-left: 22px;
}

.ugf-form .input-block .country-select-wrap .country-select .flag-dropdown .selected-flag:hover {
  background: #ffffff;
}

.ugf-form .input-block .country-select-wrap .country-select .flag-dropdown .selected-flag .flag:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 32px;
  width: 44px;
  background: "flag-crcle";
  content: '';
}

.ugf-form .input-block .country-select-wrap .country-select .flag-dropdown .selected-flag .arrow {
  border: 0;
}

.ugf-form .input-block .country-select-wrap .country-select #country {
  height: 70px;
  width: 100%;
  display: block;
  padding-left: 80px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  border: 0;
  outline: none;
  border-radius: 5px;
}

.ugf-form .input-block .country-select-wrap .country-select #country:focus {
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  border: 0 !important;
  outline: none;
}

.ugf-form .input-block .country-select-wrap .country-select .country-list {
  max-height: 300px;
  border-radius: 5px;
  border: 0;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.08);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ugf-form .input-block .country-select-wrap .country-select .country-list::-webkit-scrollbar {
  display: none;
}

.ugf-form .input-block .country-select-wrap .country-select .country-list .country {
  padding: 10px;
}

.ugf-form .input-block .country-select-wrap .country-select .country-list .country .flag {
  position: relative;
}

.ugf-form .input-block .country-select-wrap .country-select .country-list .country .flag:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 32px;
  width: 44px;
  background: "url(../images/flag-circle.png)";
  content: '';
}

.ugf-form .input-block .country-select-wrap .country-select .flag {
  width: 40px;
}

.ugf-form .input-block .country-select-wrap .country-select.inside .flag-dropdown:hover .selected-flag {
  background: #ffffff;
}

.ugf-form .input-block .custom-form-group {
  position: relative;
  margin-bottom: 25px;
}

.ugf-form .input-block .custom-form-group .custom-form-control {
  display: none;
}

.ugf-form .input-block .custom-form-group .custom-form-control:checked~label {
  border-color: rgba(30, 133, 255, 0.5);
}

.ugf-form .input-block .custom-form-group .custom-form-control:checked~label .text {
  color: #1e85ff;
}

.ugf-form .input-block .custom-form-group .custom-form-control:checked~label .icon {
  opacity: 1;
}

.ugf-form .input-block .custom-form-group .custom-form-control:checked~label:before {
  background: #1e85ff;
  border-color: transparent;
}

.ugf-form .input-block .custom-form-group .custom-form-control:checked~label:after {
  opacity: 1;
}

.ugf-form .input-block .custom-form-group label {
  margin-bottom: 0;
  width: 100%;
  border: 0;
  border-radius: 5px;
  height: 60px;
  line-height: 60px;
  padding: 0 25px;
  text-align: left;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 50px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  height: 70px;
}

.ugf-form .input-block .custom-form-group label .text {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  color: #546274;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.ugf-form .input-block .custom-form-group label .icon {
  opacity: .5;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.ugf-form .input-block .custom-form-group label:before {
  position: absolute;
  top: 25px;
  left: 20px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.08);
  content: '';
  opacity: 1;
}

.ugf-form .input-block .custom-form-group label:after {
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 21px;
  border-radius: 50%;
  content: "check-green"
}

.ugf-form .input-block .file-input-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ugf-form .input-block .file-input-wrap .custom-file {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33% - 10px);
  flex: 0 0 calc(33.33% - 10px);
  height: auto;
  text-align: center;
  margin-bottom: 25px;
}

.ugf-form .input-block .file-input-wrap .custom-file .custom-file-input {
  display: none;
}

.ugf-form .input-block .file-input-wrap .custom-file .custom-file-label {
  position: relative;
  height: 170px;
  padding: 0;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 5px;
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
}

.ugf-form .input-block .file-input-wrap .custom-file .custom-file-label:after {
  content: '';
  padding: 0;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  border: 1px dashed rgba(178, 190, 204, 0.5);
  background: transparent;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ugf-form .input-block .file-input-wrap .custom-file .text {
  margin-top: 10px;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 400;
  color: #546274;
  font-family: "Roboto", sans-serif;
}

.ugf-form .input-block .conditions ul {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ugf-form .input-block .conditions ul li {
  padding-left: 25px;
  position: relative;
  font-size: 1.3rem;
  font-weight: 400;
  color: #546274;
  font-family: "Roboto", sans-serif;
}

.ugf-form .input-block .conditions ul li:before {
  position: absolute;
  top: 3px;
  left: 0;
  content: "check-greay"
}

.ugf-form .input-block .conditions ul li.complete:before {
  content: "check-green"
}

.ugf-form .input-block .conditions ul li:nth-child(odd) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}

.ugf-form .input-block .conditions ul li:nth-child(even) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
}

.ugf-form .input-block .form-group {
  margin-bottom: 25px;
}

.ugf-form .input-block .form-group label {
  font-size: 1.4rem;
  font-weight: 700;
  color: #1c2437;
  font-family: "Roboto", sans-serif;
}

.ugf-form .input-block .form-group .form-control {
  height: 70px;
  border-radius: 5px;
  border: 0;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  outline: none;
  background: #ffffff;
  padding: 0 20px;
  font-size: 1.5rem;
  color: #546274;
}

.ugf-form .input-block .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b2becc;
}

.ugf-form .input-block .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #b2becc;
}

.ugf-form .input-block .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #b2becc;
}

.ugf-form .input-block .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #b2becc;
}

.ugf-form .input-block .form-group .custom-checkbox {
  margin-bottom: 10px;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-input {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 3px solid rgba(0, 0, 0, 0.08);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background: #1e85ff;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-label {
  width: auto;
  text-align: left;
  position: static;
  top: auto;
  left: auto;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  padding-left: 30px;
  cursor: default;
  font-weight: 400;
  color: #546274;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-label:before {
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: 2px solid rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-label:after {
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
}

.ugf-form .input-block .form-group .custom-checkbox .custom-control-label a {
  color: #1e85ff;
}

.ugf-form .btn {
  height: 70px;
  width: 100%;
  line-height: 70px;
  padding: 0;
  border-radius: 5px;
  background: #1e85ff;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ugf-form .back-to-prev {
  font-size: 1.5rem;
  font-weight: 700;
  color: #97a2ae;
  font-family: "Roboto", sans-serif;
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ugf-form .back-to-prev img {
  margin-right: 10px;
}

.footer-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-wrap .copyright {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.footer-wrap .copyright p {
  font-size: 1.4rem;
  font-weight: 400;
  color: #546274;
  font-family: "Roboto", sans-serif;
}

.footer-wrap .copyright p a:hover {
  color: #1e85ff;
}

.footer-wrap .footer-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-wrap .footer-nav li+li {
  margin-left: 20px;
}

.footer-wrap .footer-nav li a {
  font-size: 1.4rem;
  font-weight: 400;
  color: #546274;
  font-family: "Roboto", sans-serif;
}

.footer-wrap .footer-nav li a:hover {
  color: #1e85ff;
}

.uploaded-documents {
  margin-top: 42px;
}

.uploaded-documents h4 {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #1c2437;
  margin-bottom: 10px;
}

.uploaded-documents .documents {
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin: 0;
  list-style: none;
}

.uploaded-documents .documents li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

.uploaded-documents .documents li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\f0c6';
  font-family: 'Line Awesome Free';
  font-weight: 900;
  color: #b2becc;
}

.uploaded-documents .documents li a img {
  height: 15px;
}

.uploaded-documents .documents li+li {
  margin-top: 10px;
}

.final-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  padding: 120px 0;
  text-align: center;
}

.final-content .icon {
  margin-bottom: 20px;
}

.final-content h2 {
  font-size: 3.6rem;
  font-weight: 700;
  color: #1c2437;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

.final-content p {
  font-size: 1.5rem;
  max-width: 500px;
  margin: 0 auto;
  font-weight: 400;
  color: #546274;
  font-family: "Roboto", sans-serif;
}

.final-content .btn {
  margin-top: 30px;
  height: 60px;
  width: 200px;
  text-align: center;
  line-height: 60px;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #1e85ff;
  border-radius: 5px;
}

@media all and (max-width: 1199px) {
  .ugf-progress {
    position: relative;
  }

  .ugf-progress .progress {
    max-width: 250px;
  }
}

@media all and (max-width: 991px) {
  .ugf-progress {
    position: relative;
  }

  .ugf-progress .progress {
    position: absolute;
    top: -40px;
    z-index: 1;
  }
}

@media all and (max-width: 767px) {
  .navigation .logo img {
    height: 35px;
  }

  .navigation .nav-btns .back {
    display: none;
  }

  .navigation .nav-btns .get {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 1.2rem;
  }

  .footer-wrap {
    display: block;
  }

  .footer-wrap .copyright {
    text-align: center;
  }

  .footer-wrap .footer-nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .footer-wrap .footer-nav li {
    margin-right: 10px;
    margin-left: 10px;
  }

  .footer-wrap .footer-nav li+li {
    margin-left: 10px;
  }
}

@media all and (max-width: 500px) {
  .ugf-form .input-block .file-input-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .ugf-form .input-block .file-input-wrap .custom-file {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
  }

  .ugf-form .input-block .conditions ul {
    display: block;
  }

  .ugf-form .input-block .conditions ul li+li {
    margin-top: 5px;
  }
}
</style>