<template>
  <SettingsLayout>
    <div class="card h-100">
      <div class="card-body p-3">
        <h6 class="main-title">Change Password</h6>
        <form @submit.prevent="Change_pass">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="row py-3 gy-3">
            <div class="col-12">
              <div class="form-group" :class="{ 'form-group--error': $v.form.old_password.$error }">
                <label class="form-check-label label_fw form-check_cus">Old Password</label><br />
                <input class="form-control shadow-none" placeholder="Enter the Password"
                  v-model.trim="$v.form.old_password.$model" />
                <div class="error" v-if="submitted && !$v.form.old_password.required">
                  Password is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group position-relative" :class="{ 'form-group--error': $v.form.new_password.$error }">
                <label class="form-check-label label_fw form-check_cus">New Password</label><br />
                <div>
                  <input class="form-control shadow-none" placeholder="Enter the New Password"
                    v-model.trim="$v.form.new_password.$model" :type="this.type" />
                  <div class="show-hide_password">
                    <span class="eye_icon">
                      <i v-if="type == 'password'" class="fas fa-eye-slash" @click="type = 'text'"></i>
                      <i v-else class="fas fa-eye" @click="type = 'password'"></i>
                    </span>
                  </div>
                </div>
                <div class="error" v-if="submitted && !$v.form.new_password.required">
                  Password is required.
                </div>
                <div class="error" v-if="submitted && !$v.form.new_password.minLength">
                  Password must be 8 character
                </div>
                <div class="error" v-if="
                  submitted &&
                  !$v.form.new_password.valid &&
                  $v.form.new_password.required &&
                  $v.form.new_password.minLength
                ">
                  Password should contain atleast One Uppercase, One Lowercase and One
                  Special Chacter
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group position-relative" :class="{ 'form-group--error': $v.form.confirm_password.$error }">
                <label class="form-check-label label_fw form-check_cus">Confirm Password</label><br />
                <div>
                  <input class="form-control shadow-none" placeholder="Enter the Password"
                    v-model.trim="$v.form.confirm_password.$model" :type="this.type2" />

                  <div class="show-hide_password">
                    <span class="eye_icn">
                      <i v-if="type2 == 'password'" class="fas fa-eye-slash" @click="type2 = 'text'"></i>
                      <i v-else class="fas fa-eye" @click="type2 = 'password'"></i>
                    </span>
                  </div>
                </div>
                <div class="error" v-if="submitted && !$v.form.confirm_password.required">
                  Confirm Password is required
                </div>
                <div class="error" v-if="submitted && !$v.form.confirm_password.sameAsPassword && form.confirm_password">
                  Password and Confirm Password is not match.
                </div>
              </div>
            </div>
            <div class="col-12">
              <div v-if="loading" class="d-flex justify-content-center btn btn-action w-100">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

              <div v-else>
                <button class="btn btn-primary w-100" type="submit">submit</button>
              </div>
            </div>
          </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SettingsLayout>
</template>



<script>
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import ApiClass from "@/api/api";
// import ActivityLogVue from './ActivityLog.vue';
// import LoginVue from '../Auth/Login.vue';
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "Change-Password",
  components: {
    SettingsLayout,
  },
  data() {
    return {
      type: "password",
      type2: "password",
      submitted: false,
      loading: false,
      form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  validations: {
    form: {
      old_password: {
        required,
        // valid: function(value) {
        //     // return this.check(value);
        // },
        minLength: minLength(8),
      },
      new_password: {
        required,
        valid: function (value) {
          return this.check(value);
        },
        minLength: minLength(8),
      },
      confirm_password: {
        required,
        valid: function (value) {
          return this.check(value);
        },
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  mounted() { },
  methods: {
    check(value) {
      const containsUppercase = /[A-Z]/.test(value);
      const containsLowercase = /[a-z]/.test(value);
      const containsSpecial = /[#?!@$%^&*-]/.test(value);
      return containsUppercase && containsLowercase && containsSpecial;
    },
    async Change_pass() {
      this.submitted = true;
      // console.log(this.$v.form);
      if (this.$v.form.$invalid) {
        return;
      }

      // console.log("hello");
      var result = await ApiClass.postRequest(
        "user/change_password",
        true,
        this.form
      );
      console.log(result);
      if (result.data.status_code == 1) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        this.submitted = false;
        this.form.old_password = "";
        this.form.new_password = "";
        this.form.confirm_password = "";
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });

        this.submitted = false;
      }
    },
  },
};
</script>


<style scoped>
.name_setting_box p:nth-child(1) {
  font-weight: 600;
}

.form-box {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 75px 75px;
}

.label_fw {
  font-weight: 500;
}

.input_style_cus:focus {
  box-shadow: 0;
  border-color: rgb(170, 170, 170);
}

.fw-400 {
  font-weight: 400;
}

label.form-check_cus,
input.form-check_cus {
  cursor: pointer;
}

.change_password .form-control:focus {
  border: 1px solid rgba(143, 143, 143, 0.8) !important;
}

.show-hide_password {
  position: absolute;
  right: 10px;
  top: 32px;
}
</style>