<template>
  <div class="card-tm">
    <div class="container container-row">
      <div class="row gy-3">
        <div class="col-md-12">
          <TopHeading :data="topHeading" />
        </div>
        <div class="col-12 py-3">
          <div class="card">
            <div class="card-body shadow-sm">
              <div class="table-container table-height table-responsive custom-scrollbar" ref="tableContainer">
                <table class="table table-borderless align-middle" id="table-list" ref="tablelist">
                  <thead class="">
                    <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
                      <th scope="col" v-for="(item, index) in tableHeading" :key="index">{{
                        item
                      }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="skeletonLoad">
                      <tr class="align-middle" v-for="i in tableHeading.length" :key="i">
                        <td class="text-uppercase" v-for="j in tableHeading.length" :key="j">
                          <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                            wave-color="#fff" :rounded="true" />
                        </td>
                      </tr>
                    </template>
                    <template v-if="activityLog.length >= 1 && !skeletonLoad">
                      <tr class="border-0" v-for="(item, index) in activityLog" :key="index">
                        <td>
                          <div class="d-flex gap-2 align-items-center">
                            <!-- <img src="https://tradebit.io/backend/public/currency_images/tether.png" width="23"> -->
                            <h6 class="fs-15 m-0 fw-500">{{ item.launch_token.symbol }}</h6>
                          </div>
                        </td>
                        <td>
                          <h6 class="subtitle">{{ item.amount }}</h6>
                        </td>
                        <td>
                          <h6 class="subtitle">{{ item.price }}</h6>
                        </td>
                        <td>
                          <h6 class="subtitle">{{ item.total }}</h6>
                        </td>
                        <td>
                          <h6 class="subtitle">{{ item.currency }}</h6>
                        </td>
                        <td>
                          <span class="subtitle text-capitalize"><span class="badge text-capitalize"
                              :class="item.status == 'completed' ? 'success-badge' : 'danger-badge'">{{
                                item.status
                              }}</span></span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div class="col-12 text-center" v-if="loading && !skeletonLoad">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div v-if="activityLog.length < 1 && !skeletonLoad"
                  class="d-flex border-0 flex-column text-center justify-content-center py-4">
                  <div>
                    <img width="70" :src="require(`@/assets/images/icons/no-record.svg`)" />
                  </div>
                  <span class="fw-500 fs-14">No record found</span>
                </div>
              </div>
              <div>
                <pagination v-model="page" :records="recordData" :per-page="per_page" :options="options"
                  @paginate="getallOrders" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClass from "@/api/api";
import Pagination from "vue-pagination-2";
import TopHeading from "@/components/ui/TopHeading.vue";
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  name: "Launchpad_order",
  components: {
    Pagination,
    TopHeading,
    VueSkeletonLoader
  },
  data() {
    return {
      loading: false,
      skeletonLoad: true,
      tableHeading: ["Token", "Amount", "Price", "Total", "Currency", "Status"],
      topHeading: {
        heading: 'Orders List',
        subheading: 'Past launchpad orders list',
        buttons: [
          // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
          // {name:'Deposit', link:'/wallet/deposit', class:'btn-primary text-white'}
        ],
      },
      activityLog: [],
      page: 1,
      recordData: 0,
      per_page: 10,
      options: {
        edgeNavigation: false,
        chunksNavigation: false,
        chunk: 4,
        texts: false,
        format: false,
      },
    };
  },
  mounted() {
    this.getallOrders();
  },
  methods: {
    async getallOrders() {
      this.loading = this.skeletonLoad = true;
      await ApiClass.getNodeRequest(
        "launchpad/order_find?page=" + this.page + "&per_page=" + this.per_page,
        true
      ).then((res) => {
        console.log(res.data.data.data);
        if (res.data.status_code == "1") {
          this.activityLog = res.data.data.data;
          this.recordData = res.data.data.total;
          this.perPageData = res.data.data.per_page;
          this.loading = this.skeletonLoad = false;
          console.log(this.perPageData);
        }
      });
    },
  },
};
</script>

<style scoped>
.badge {
  font-size: 12px;
}

.success-badge {
  background-color: #CCFBF1;
}

.danger-badge {
  background-color: #FEE2E2;
}

.pending-badge {
  background-color: #ffdfa1;
}
</style>
