<template>
    <SettingsLayout>
        <div class="card h-100" id="profile-settings">
            <div class="card-body p-4">
                <div class="row gy-4 justify-content-between">
                    <div class="col-12" v-if="this.userData">
                        <h6 class="main-title">My Profile</h6>
                        <div class="d-flex gap-3 py-3 align-items-center">
                            <div>
                                <img src="../../../public/assets/images/avt/user.svg" alt="" class="img-fluid"
                                    width="50" />
                            </div>
                            <div>
                                <div class="col-12">
                                    <span id="email-col">{{ this.userData.email }}</span> <span class="mx-2"><span class="fw-500">UID: </span> <span class="fw-600">{{ this.userData.username }}</span></span>
                                </div>
                                <div class="col-12 fs-14 mt-1"><span class="fw-500">Last Login time :</span><span class="fw-600"> {{new Date(this.recentLogin.created_at).toLocaleString()}}</span></div>
                            </div>
                            <!-- <div><span id="email-col">{{ this.userData.email }}</span></div>
                            <div class="ms-3"><span class="fw-500">UID :</span></div>
                            <div><span class=" fw-600">{{ this.userData.username }}</span></div>
                            <div><span class="badge btn-light">{{ this.status }}</span></div> -->
                        </div>
                    </div>
                </div>
                <hr v-if="this.userData" />
                <!-- KYC Verification -->
                <div class="row gy-4 py-3 justify-content-between">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-lg-row flex-column">
                            <div>
                                <h6 class="title">KYC Verification</h6>
                                <span class="sub-title font-secondary">To protect your funds, please complete identity
                                    verification first</span>
                            </div>
                            <div>
                                <router-link v-if="user.user_kyc_status == 'new' || user.user_kyc_status == 'rejected'"
                                    to="/settings/kyc" class="btn btn-primary mt-3 btn-sm text-white">Verify now</router-link>
                                <button v-if="user.user_kyc_status == 'pending'" class="btn btn-primary mt-3 btn-sm">
                                    <i class="fa fa-clock"></i> Under Review
                                </button>
                                <button v-if="user.user_kyc_status == 'completed'" class="btn btn-success mt-3 btn-sm">
                                    <i class="fa fa-circle-check"></i> Verified
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <!-- <div class="col-12 col-lg-6">
                        <h6 class="title">My Fees</h6>
                        <span class="sub-title font-secondary">Upgrade your Profile to enjoy lower fees</span>
                        <div class="d-flex gap-3">
                            <span class="mt-3 fs-14"><span class="font-secondary">Maker </span> <span class="fw-600">50%
                                    Off</span></span>
                            <span class="mt-3 fs-14"><span class="font-secondary">Taker </span> <span class="fw-600">50%
                                    Off</span></span>
                        </div>
                    </div> -->

                <!-- Security Settings -->
                <div class="row py-2 gy-3 justify-content-between">
                    <div class="col-12">
                        <h6 class="title">Security Settings</h6>
                        <span class="fs-14"><span class="font-secondary">Your current safety strength:</span><span
                                class="fw-600"> Too Weak</span></span>
                    </div>
                    <div class="col-12">
                        <div
                            class="d-flex justify-content-between  align-items-lg-center flex-wrap flex-lg-row flex-column">
                            <span class="fs-16 fw-500">SMS setup</span>
                            <span class="fs-14 fw-500 font-light-gray">For login, password reset, and change of security
                                settings</span>
                            <router-link to="/settings/security" class="btn text-white btn-primary mt-3 btn-sm">Setup</router-link>
                        </div>
                    </div>
                    <div class="col-12">
                        <div
                            class="d-flex justify-content-between  align-items-lg-center flex-wrap flex-lg-row flex-column">
                            <span class="fs-16 fw-500">Email setup</span>
                            <span class="fs-14 fw-500 font-light-gray">For login, password reset, and change of security
                                settings</span>
                            <router-link to="/settings/security" class="btn text-white btn-primary mt-3 btn-sm">Setup</router-link>
                        </div>
                    </div>
                    <div class="col-12">
                        <div
                            class="d-flex justify-content-between  align-items-lg-center flex-wrap flex-lg-row flex-column">
                            <span class="fs-16 fw-500">Google Authenticator</span>
                            <span class="fs-14 fw-500 font-light-gray">Extra Layer For login, password reset, and change
                                of security
                                settings</span>
                            <router-link to="/settings/security" class="btn text-white btn-primary mt-3 btn-sm">Setup</router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <!-- Content  -->
    </SettingsLayout>
</template>



<script>
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import ApiClass from "@/api/api";
// import ActivityLogVue from './ActivityLog.vue';
// import LoginVue from '../Auth/Login.vue';
export default {
    name: "Profile",
    components: {
        SettingsLayout,
    },
    data() {
        return {
            userData: null,
            recentLogin: null,
            status: null,
            user: {},
        }
    },
    mounted() {
        this.getUserInfo();
        this.callback();
        this.userDatas();
    },
    methods: {
        userDatas() {
     //       var userdata = localStorage.getItem("user");
   //         this.user = JSON.parse(userdata);
        },
        async getUserInfo() {
            await ApiClass.getNodeRequest('user/get', true)
                .then((res) => {
                    if (res.data.status_code == 1) {
                        this.userData = res.data.data;
                        this.status = res.data.data.status == true ? 'Active' : 'Blocked';

                    }

                })

        },
        async callback() {
            await ApiClass.getRequest("log/getRecentLogin", true).then((res) => {
                if (res.data.status_code == "1") {
                    this.recentLogin = res.data.data;
                }
            });
        }

    }
};

</script>


<style scoped>
.name_setting_box p:nth-child(1) {
    font-weight: 600;
}
</style>